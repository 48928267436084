import React from "react";
import { LPShowcaseSection } from "../../api/Types";

type Props = LPShowcaseSection;

const LandingPageShowcase: React.FC<Props> = ({
  style,
  title,
  subtitle,
  textAlign,
  titleTextAlign,
  countTiles,
  tiles,
  imageMaxWidth,
  imageBackgroundColor,
  cta,
}) => {

  return (
    <div>
      {title && (
        <h2
          className="multi-tile-section-title"
          style={{ textAlign: titleTextAlign }}
        >
          {title}
        </h2>
      )}
      {subtitle && (
        <p
          className="multi-tile-section-subtitle"
          style={{ textAlign: titleTextAlign }}
        >
          {subtitle}
        </p>
      )}

      {tiles &&
        <div className='showcase-section__wrapper'>
          {tiles.map((tile) => {
            return (
              <div className='showcase-section__item-container'>
                <div className='showcase-section__icon-wrapper'>
                  {tile.icon}
                </div>
                <div className='showcase-section__text-container'>
                  <span className='showcase-section__title-text'>{tile.title}</span>
                  <span className='showcase-section__description-text'>{tile.description}</span>
                </div>
              </div>
            )
          })}
        </div>
      }
    </div>
  );
};

export default LandingPageShowcase;
