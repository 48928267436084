import React from "react";
import { LPHighlightedSteps } from "../../api/Types";
import { Button } from "../../../../../../src/components/ui/button";
import { IconArrowRight } from "@tabler/icons-react";

type Props = LPHighlightedSteps;

const LandingPageHighlightedSteps: React.FunctionComponent<Props> = ({
  style,
  title,
  subtitle,
  steps,
  ctaBtn,
}) => {
  return (
    <section
      className="landing-page-section bg-brand-500 py-16 px-4 text-white"
      style={{ ...style }}
    >
      <div className="mb-16 text-center">
        <h2 className="mb-xl text-display-md-bold">{title}</h2>
        <p className="mb-5x text-lg-regular">{subtitle}</p>
        {ctaBtn && (
          <Button
            variant="secondaryGray"
            className={"no-underline mt-lg"}
            size="xl"
            asChild
          >
            <a href={ctaBtn.linkUrl} target="_blank">{ctaBtn.label}</a>
          </Button>
        )}
      </div>
      <div className="flex flex-col justify-center items-center md:flex-row md:justify-items-center md:gap-6xl md:items-start">
        {steps?.map((step, index) => (
          <div key={index} className="flex flex-col space-x-4 gap-3xl items-center mb-3xl md:flex-row md:gap-6xl md:mb-0 md:items-center">
            <div className="flex flex-col items-center gap-4">
              <img
                src={step.image_url}
                alt={step.alt_text || "Step Image"} 
                width={400}
                className="h-12 w-400 h-auto"
              />
              <p className="text-center w-100% text-xl-regular ">{step.description}</p>
            </div>
            {index !== steps.length - 1 && (
              <div className="flex items-center transform rotate-90 mx-auto my-auto md:rotate-0 md:mx-0 md:my-0">
                <IconArrowRight stroke={2} size={80} />
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default LandingPageHighlightedSteps;
