import React from "react";
import { useRequestContext } from "../../../common/providers/RequestProvider";
import { LPMultiTileSection, MultiTile } from "../../api/Types";
import DynamicCallToAction from "../common/DynamicCallToAction";
import LandingPageSectionWrapper from "../common/LandingPageSectionWrapper";
import { Button } from "../../../../../../src/components/ui/button";
import BlogPostCard from "../../../vention_ui/marketing_website_components/BlogPostCard";

type Props = LPMultiTileSection<MultiTile>;

type MultiTile = {
  readonly title: string;
  readonly titlePrefix?: string;
  readonly imageUrl: string;
  readonly imageAlt?: string;
  readonly description: string;
  readonly linkUrl: string;
  readonly linkLabel: string;
  readonly icon?: React.ReactNode;
}

const LandingPageLinkedMultiCard: React.FunctionComponent<Props> = ({
  style,
  id,
  supertitle,
  title,
  subtitle,
  textAlign,
  titleTextAlign,
  countTiles,
  tiles,
  imageMaxWidth,
  imageBackgroundColor,
  ctaBtn,
  cta,
}) => {
  const { isBot } = useRequestContext();

  const loopClassNames = ["multi-tile-loop"];
  if (countTiles) {
    loopClassNames.push(`multi-tile-loop-${countTiles}`);
  }

  return (
    <LandingPageSectionWrapper
      cssNamespace="linked-multi-card-section"
      style={{ ...style, textAlign }}
      id={id}
    >
      {supertitle && (
        <h2
          className="section__supertitle"
          style={{ textAlign: titleTextAlign }}
        >
          {supertitle}
        </h2>
      )}
      {title && (
        <h2
          className="linked-multi-card-section__title"
          style={{ textAlign: titleTextAlign }}
        >
          {title}
        </h2>
      )}
      {subtitle && (
        <p
          className="multi-tile-section-subtitle"
          style={{ textAlign: titleTextAlign }}
        >
          {subtitle}
        </p>
      )}
      {ctaBtn && (
        <Button
          className={"no-underline hover:text-white focus:text-white mb-5"}
          size="xl"
          asChild
        >
          <a href={ctaBtn.linkUrl} target="_blank">
            {ctaBtn.label}
          </a>
        </Button>
      )}
      {countTiles && tiles && (
        <div className="linked-multi-card-section__loop-wrapper">
          <div className={loopClassNames.join(" ")}>
            {tiles.map((tile: MultiTile, idx: number) => {
              const { title, imageUrl, description, linkUrl } = tile;
              return (
                <div className="linked-multi-card-section__container">
                  <BlogPostCard
                    key={idx}
                    title={title}
                    image={imageUrl}
                    description={description}
                    link={linkUrl}
                    theme="dark"
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {cta && <DynamicCallToAction cta={cta} />}
    </LandingPageSectionWrapper>
  );
};

export default LandingPageLinkedMultiCard;
